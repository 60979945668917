<div class="bg-color-ground text-color-ground container-fluid pb-3">
  <div class="row justify-content-center">
    <!-- Own style hr tag -->
    <div class="row w-100 px-3 px-sm-5 pb-3 pb-sm-5">
      <div class="hr-border col-12"></div>
    </div>
    <div class="col-11 col-xs-10 col-md-9 col-xl-6">
      <div class="row">
        <!-- Google map -->
        <div class="col-sm-6 order-2">
          <div class="gmap-canvas">
            <iframe
              class="gmap-iframe"
              src="https://maps.google.com/maps?q=Bor%C3%B3ka%20Tanya%20kisk%C5%91r%C3%B6s&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </div>
        <!-- Contact data -->
        <div class="col-sm-6 order-1 order-sm-2 mb-5 mb-sm-0">
          <div class="text-footer-title mb-4 text-center">Elérhetőségeink</div>
          <div class="row justify-content-center">
            <div class="col-auto">
              <div class="text-footer d-flex">
                <!-- Person icon -->
                <svg
                  class="person-icon mr-3 svg-inline--fa fa-user fa-w-14"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="user"
                  role="img"
                  viewBox="0 0 448 512">
                  <path
                    fill="currentColor"
                    d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z">
                  </path>
                </svg>
                Jávor Orsolya
              </div>
              <div class="text-footer my-2 d-flex">
                <!-- Mobile icon -->
                <svg
                  class="mobile-icon mr-3 svg-inline--fa fa-mobile-alt fa-w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="mobile-alt"
                  role="img"
                  viewBox="0 0 320 512">
                  <path
                    fill="currentColor"
                    d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z">
                  </path>
                </svg>
                +36-70-883-8386
              </div>
              <div class="text-footer d-flex">
                <!-- Mail icon -->
                <svg
                  class="mail-icon mr-3 svg-inline--fa fa-envelope fa-w-16"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="envelope"
                  role="img"
                  viewBox="0 0 512 512">
                  <path
                    fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                  </path>
                </svg>
                info@borokaszallas.hu
              </div>
              <div class="row mt-4 mt-sm-5 justify-content-center">
                <!-- Call button -->
                <div class="col-auto">
                  <a href="tel:+36708838386">
                    <button class="contact-button-shema footer-page-button btn btn-light text-center" type="button">
                    Hívjon MOST!
                    <!-- Call icon -->
                    <svg
                      class="call-icon svg-inline--fa fa-phone fa-w-16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="phone"
                      role="img"
                      viewBox="0 0 512 512">
                      <path
                        fill="currentColor"
                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                      </path>
                    </svg>
                    </button>
                  </a>
                </div>
                <div class="col-auto">
                  <!-- Facebook brand icon -->
                  <a class="text-decoration-none" href="https://www.facebook.com/Bor%C3%B3ka-h%C3%A1z-107891194677074" target="_blank">
                    <svg
                      class="facebook-brand-shema footer-page-facebook-brand svg-inline--fa fa-facebook-square fa-w-14"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="facebook-square"
                      role="img"
                      viewBox="-10 22 468 468"><path
                      fill="currentColor"
                      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z">
                      </path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright and made by section -->
    <div class="row w-100 px-3 px-sm-5 pt-3 pt-sm-5">
      <!-- Own style hr tag -->
      <div class="hr-border col-12"></div>
      <div class="text-footer-right col-12 pt-3 text-center text-sm-right">Minden jog fenntartva &#169; {{date() | date:'yyyy'}}</div>
      <div class="text-footer-right col-12 pt-1 text-center text-sm-right">Készítette <a class="made-by text-color-ground text-decoration-none" href="https://papp-viktor-tibor.hu/" target="_blank"><b>Papp Viktor Tibor</b></a></div>
    </div>
  </div>
</div>
