import { Component, OnDestroy, OnInit } from '@angular/core';

import { SeoService } from '../shared/seo.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit, OnDestroy {

  constructor(private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.generateTags({
      title: "Boróka Ház - Programok",
      description: "Soltvadkert, Vadkerti-tó, Strand, Tanösvény, Kalandpark, Kerékpározás, Horgászat, Szent Korona Cukrászda, Motormúzeum, méhészet, kecskesimogató, sajtkészítés, termálfürdő, Kiskőrös, Petőfi szülőháza, Úttörténeti múzeum, Kiskőrösi Termálfürdő, szélmalom, Kiskunhalasi Termálfürdő, Halasi csipkemúzeum, Kiskunmajsai Termálfürdő, Hajósi pincefalu, Kecel, Hadtörténeti múzeum"
    })
  }

  ngOnDestroy(): void {
    this.seo.generateTags({
      slug: ""
    })
  }
}
