import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-further-information-button',
  templateUrl: './further-information-button.component.html',
  styleUrls: ['./further-information-button.component.css']
})
export class FurtherInformationButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
