<app-apartment></app-apartment>
<div class="text-color-ground container-fluid pt-5 pb-3 pb-sm-5">
  <div class="row justify-content-center pt-5">
    <!-- Card shema -->
    <div class="border-card col-11 col-xs-10 col-md-9 col-xl-6 px-3 px-sm-4 pt-2 pb-3">
      <div class="text-title text-center">Alsó szint</div>
      <div class="text-shell mt-4">
        Jól felszerelet konyha, akár 10-12 fő kiszolgálására, etetőszék.
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <div class="col">
          <!-- Carousel for pictures -->
          <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let kitchenImg of kitchenImages; index as i">
              <img class="house-image" [src]="kitchenImg" alt="Konyha: {{i+1}}. kép" (click)="openModal(modalContent, $event)">
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="text-shell mt-4">
        Különálló, ajtóval elválasztott nappali nagy, bővíthető asztallal,
        ideális akár 10 fős kártyapartihoz.
        <!-- Smile icon -->
        <svg
          class="emoji svg-inline--fa fa-smile fa-w-16"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="smile"
          role="img"
          viewBox="0 0 496 512">
          <path
            fill="currentColor"
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
          ></path>
        </svg>
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <div class="col">
          <!-- Carousel for pictures -->
          <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let livingRoomImg of livingRoomImages; index as i">
              <img class="house-image" [src]="livingRoomImg" alt="Nappali: {{i+1}}. kép" (click)="openModal(modalContent, $event)">
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="text-shell mt-4">
        Fürdőszoba zuhannyal, wc-vel, mosógéppel, ha hosszabb időt töltenétek
        nálunk. Babakád.
      </div>
      <div class="text-shell mt-4">
        Boróka szoba: egy dupla ágy, plusz egy szimpla ágy.
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <!-- Picture of the house -->
        <div class="col">
            <img class="house-image" src="assets/haz-boroka-szoba.jpg" alt="Boróka szoba" (click)="openModal(modalContent, $event)">
        </div>
      </div>
      <div class="text-shell mt-4">
        Bendegúz szoba: franciaágy plusz egy szimpla ágy (ajánlott 14 éves
        korig).
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <!-- Picture of the house -->
        <div class="col">
            <img class="house-image" src="assets/haz-bendeguz-szoba.jpg" alt="Bendegúz szoba" (click)="openModal(modalContent, $event)">
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center pt-5">
    <!-- Card shema -->
    <div class="border-card col-11 col-xs-10 col-md-9 col-xl-6 px-3 px-sm-4 pt-2 pb-3">
      <div class="text-title text-center">Felső szint</div>
      <div class="text-shell mt-4">
        Az alsó nappaliból falépcsőn jutsz fel a felső szint nappalijába, amely
        nyitott, de szükség esetén egy nyitható kétszemélyes kanapé áll
        rendelkezésre.
      </div>
      <div class="text-shell mt-4">Wc mosdóval.</div>
      <div class="text-shell mt-4">
        Szembe a Bordó szoba, hatalmas franciaággyal, itt egy kinyitható pótágy
        is rendelkezésre áll igény szerint.
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <!-- Picture of the house -->
        <div class="col">
            <img class="house-image" src="assets/haz-bordo-szoba.jpg" alt="Bordó szoba" (click)="openModal(modalContent, $event)">
        </div>
      </div>
      <div class="text-shell mt-4">
        Oldalról nyílik a Bodza szoba, kicsi, kuckós, de itt is franciaágy áll.
      </div>
      <div class="row mt-2 pb-0 pb-sm-2 justify-content-center">
        <!-- Picture of the house -->
        <div class="col">
            <img class="house-image" src="assets/haz-bodza-szoba.jpg" alt="Bodza szoba" (click)="openModal(modalContent, $event)">
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center pt-5">
    <!-- Card shema -->
    <div class="border-card col-11 col-xs-10 col-md-9 col-xl-6 px-3 px-sm-4 pt-2 pb-4">
      <div class="text-title text-center">Terasz, kert</div>
      <div class="row mt-4 justify-content-center">
        <div class="col">
          <!-- Carousel for pictures -->
          <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let terraceGarden of terraceGardenImages; index as i">
              <img class="house-image" [src]="terraceGarden" alt="Terasz, kert: {{i+1}}. kép" (click)="openModal(modalContent, $event)">
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>
  </div>
  <!-- Carousel frame -->
  <ng-template #modalContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{imgAlt}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body text-center">
      <img class="modal-image" [src]="imgUrl" [alt]="imgAlt">
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Bezár</button>
    </div>
  </ng-template>
</div>
<app-footer></app-footer>
