import { Component, OnInit } from '@angular/core';

import { SeoService } from '../shared/seo.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

  constructor(private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.generateTags({
      title: "Boróka Ház - Áraink",
      description: "olcsó, alacsony ár, akciós ár, nagy, tágas, egész ház, alsó szint, felső szint, 4 fő, 6 fő, 8 fő, 10 fő, 12 fő, 14 fő, 3572/fő, 3112/fő, 3637/fő, 3000/fő, ingyenes, ingyen, törölköző ingyen, ágynemű ingyen, gyerekkád ingyen, etetőszék ingyen"
    })
  }

  ngOnDestroy(): void {
    this.seo.generateTags({
      slug: ""
    })
  }
}
