<nav class="row w-100 m-auto justify-content-center">
  <a class="nav-link" routerLink="/fooldal" routerLinkActive="active"
    >Főoldal</a
  >
  <a class="nav-link" routerLink="/ahaz" routerLinkActive="active"
    >A ház</a
  >
  <a class="nav-link" routerLink="/programok" routerLinkActive="active"
    >Programok</a
  >
  <a class="nav-link" routerLink="/araink" routerLinkActive="active"
    >Áraink</a
  >
  <a class="nav-link" routerLink="/kapcsolat" routerLinkActive="active"
    >Kapcsolat</a
  >
</nav>
