import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './main-page/main-page.component';
import { TheHouseComponent } from './the-house/the-house.component';
import { ProgramsComponent } from './programs/programs.component';
import { PricesComponent } from './prices/prices.component';
import { ContactComponent } from './contact/contact.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/fooldal', pathMatch: 'full'},
  { path: 'fooldal', component: MainPageComponent },
  { path: 'ahaz', component: TheHouseComponent },
  { path: 'programok', component: ProgramsComponent },
  { path: 'araink', component: PricesComponent },
  { path: 'kapcsolat', component: ContactComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
