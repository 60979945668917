<app-apartment></app-apartment>
<div class="text-color-ground container-fluid py-5">
  <div class="row justify-content-center pt-4 pt-sm-0">
    <div id="price-card">
      <div class="text-title text-center">Árak 2023</div>
      <div class="text-shell my-4">Igény szerint csak az alsó szint, vagy az egész ház bérelhető.</div>
      <!-- Card shema -->
      <div class="border-card  row px-0 px-sm-4 py-2 py-sm-3">
        <div class="col">
          <div class="text-shell-title"><b><u>Alsó szint</u></b></div>
          <div class="row mt-1">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Boróka szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 dupla ágy</div>
              <div class="text-shell">- 1 szimpla ágy</div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Bendegúz szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 franciaágy</div>
              <div class="text-shell">- 1 szimpla ágy</div>
              <!-- <div class="text-shell">(14 éves korig)</div>-->
            </div>
          </div>
          <div class="text-shell text-right mt-2 mt-sm-4">30.000.- /éj</div>
          <!-- Own style hr tag -->
          <!-- <div class="hr-border col-12 my-4"></div>
          <div class="text-shell">Nappaliban ágyazva +2 főnek</div>
          <div class="text-shell text-right mt-2 mt-sm-4">28.000.- /éj</div>-->
        </div>
      </div>
      <!-- Card shema -->
      <div class="border-card row mt-4 px-0 px-sm-4 py-2 py-sm-3">
        <div class="col">
          <div class="text-shell-title"><b><u>Egész ház</u></b></div>
          <div class="row mt-1">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Boróka szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 dupla ágy</div>
              <div class="text-shell">- 1 szimpla ágy</div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Bendegúz szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 franciaágy</div>
              <div class="text-shell">- 1 szimpla ágy</div>
              <!--<div class="text-shell">(14 éves korig)</div>-->
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Bordó szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 franciaágy</div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-auto pr-1 pr-sm-3">
              <div class="text-shell">Bodza szoba</div>
            </div>
            <div class="col pl-0 text-right">
              <div class="text-shell">- 1 franciaágy</div>
            </div>
          </div>
          <div class="text-shell text-right mt-2 mt-sm-4">50.000.- /éj</div>
          <!-- Own style hr tag -->
          <!-- <div class="hr-border col-12 my-4"></div>
          <div class="text-shell">Alsó + felső nappaliban ágyazva</div>
          <div class="text-shell text-right mt-2 mt-sm-4">45.000.- /éj</div>-->
        </div>
      </div>
      <!-- Card shema -->
      <div class="border-card row mt-4 px-0 px-sm-4 py-2 py-sm-3">
        <div class="col">
          <div class="row">
            <div class="col-auto">
              <div class="text-shell">IFA</div>
            </div>
            <div class="col">
              <div class="text-shell text-right">300.- /fő/éj</div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="text-shell">Kutya</div>
            </div>
            <div class="col">
              <div class="text-shell text-right">1000.- /éj</div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="text-shell">Dézsa</div>
            </div>
            <div class="col">
              <div class="text-shell text-right">6000.- /felfűtés</div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="text-shell">Gyerekkád, etetőszék</div>
            </div>
            <div class="col pl-0">
              <div class="text-shell text-right">ingyenes</div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <div class="text-shell">Törölköző, ágynemű</div>
            </div>
            <div class="col pl-0">
              <div class="text-shell text-right">ingyenes</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card shema -->
      <div class="border-card row mt-4 px-0 px-sm-4 py-2 py-sm-3">
        <div class="col">
          <div class="row">
            <div class="col-12">
              <div class="text-shell">Kiemelt időszakokban -</div>
            </div>
            <div class="col-12">
              <div class="text-shell">hosszú hétvége, ünnepek</div>
            </div>
            <div class="col-12">
              <div class="text-shell">- csak teljes ház bérelhető </div>
            </div>
            <div class="col-12 mt-3 fs-1">
              <div class="text-shell">Minimum foglalás 2 éjszaka</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
