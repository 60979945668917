import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { MainPageComponent } from './main-page/main-page.component';
import { FooterComponent } from './footer/footer.component';
import { TheHouseComponent } from './the-house/the-house.component';
import { ProgramsComponent } from './programs/programs.component';
import { PricesComponent } from './prices/prices.component';
import { PlatformLocation } from '@angular/common';
import { FurtherInformationButtonComponent } from './shared/further-information-button/further-information-button.component';
import { ContactComponent } from './contact/contact.component';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ApartmentComponent,
    MainPageComponent,
    FooterComponent,
    TheHouseComponent,
    ProgramsComponent,
    PricesComponent,
    FurtherInformationButtonComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
