import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SeoService } from '../shared/seo.service';

@Component({
  selector: 'app-the-house',
  templateUrl: './the-house.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./the-house.component.css']
})
export class TheHouseComponent implements OnInit, OnDestroy {
  @ViewChild('carousel', {static : true}) carousel!: NgbCarousel;

  //Array from the location of the pictures to the carousel
  kitchenImages = [ `assets/haz-konyha1.jpg` , `assets/haz-konyha2.jpg` ];
  livingRoomImages = [ `assets/haz-nappali.jpg` , `assets/haz-nappali-feljaro-az-emeletre.jpg` ];
  terraceGardenImages = [ `assets/haz-terasz-kert1.jpg` , `assets/haz-terasz-kert2.jpg`, `assets/haz-terasz-kert3.jpg`, `assets/haz-terasz-kert4.jpg`, `assets/haz-terasz-kert5.jpg`, `assets/haz-terasz-kert6.jpg` ];

  //Carousel options
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  closeResult!: string;

  //Image data for the modal
  imgUrl: string = "";
  imgAlt: string = "";

  constructor(private modalService: NgbModal, private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.generateTags({
      title: "Boróka Ház - A ház",
      description: "Boróka Ház, szállás, apartman, olcsó, tágas, kert, grill, alsó szint, felső szint, franciaágy, terasz, bogrács, 4 fő, 6 fő, 8 fő, 10 fő, 12 fő, 14 fő, gyerek, Boróka szoba, Bendegúz szoba, Bordó szoba, Bodza szoba, nappali, konyha"
    })
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  openModal(content: any, event: any) {
    this.imgUrl = event.target.src;
    this.imgAlt = event.target.alt;
    this.modalService.open(content, { size: 'xl' , centered: true });
  }

  ngOnDestroy(): void {
    this.seo.generateTags({
      slug: ""
    })
  }
}
