<app-apartment></app-apartment>
<div class="text-color-ground container-fluid py-5">
  <div class="row justify-content-center pt-4 pt-sm-0">
    <div class="col-11 col-xs-10 col-md-9 col-xl-6">
      <div class="text-title text-center">Soltvadkert</div>
      <div class="row">
        <!-- Card shema -->
        <div class="border-card col mt-4 px-4 py-2 py-sm-3">
          <div class="text-shell-title"><b>Vadkerti-tó</b></div>
          <div class="row">
            <div class="col-12 col-sm-auto pr-0">
              <div class="text-shell"><u>Strand:</u></div>
            </div>
            <div class="col">
              <div class="text-shell">
                szépen felújított, homokos parttal rendelkező, gyerekbarát
                strand, vízibicikli kölcsönzővel, játszótérrel, hatalmas fákkal,
                remek büfével.
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-auto pr-0">
              <div class="text-shell"><u>Tanösvény:</u></div>
            </div>
            <div class="col">
              <div class="text-shell">
                a tó körül kiépített túraútvonal, kilátóval, a hosszú séta
                szerelmeseinek.
              </div>
            </div>
          </div>
          <div class="text-shell "><u>Kalandpark</u></div>
          <div class="row py-2">
            <div class="col-12 col-sm-auto pr-0">
              <div class="text-shell"><u>Kerékpárutak:</u></div>
            </div>
            <div class="col text-center text-sm-left pt-2 pt-sm-0">
              <div class="text-shell">
                <!-- Information button -->
                <a href="https://merretekerjek.hu/#zoom=16&lat=46.6156&lon=19.39147&scope=montival" target="_blank">
                  <button class="btn btn-outline-light">
                    további információ &rArr;
                    <!-- Bicycle icon -->
                    <svg
                      class="emoji svg-inline--fa fa-biking fa-w-20"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="biking"
                      role="img"
                      viewBox="0 0 640 512">
                      <path
                        fill="currentColor"
                        d="M400 96a48 48 0 1 0-48-48 48 48 0 0 0 48 48zm-4 121a31.9 31.9 0 0 0 20 7h64a32 32 0 0 0 0-64h-52.78L356 103a31.94 31.94 0 0 0-40.81.68l-112 96a32 32 0 0 0 3.08 50.92L288 305.12V416a32 32 0 0 0 64 0V288a32 32 0 0 0-14.25-26.62l-41.36-27.57 58.25-49.92zm116 39a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64zM128 256a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 192a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"
                      ></path>
                    </svg>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div class="row py-2">
            <div class="col-12 col-sm-auto pr-0">
              <div class="text-shell"><u>Horgászati lehetőség:</u></div>
            </div>
            <div class="col text-center text-sm-left pt-2 pt-sm-0">
              <!-- Information button -->
              <a href="http://petofihorgasz.hu/" target="_blank">
                <button class="btn btn-outline-light">
                  további információ &rArr;
                  <!-- Fish icon -->
                  <svg
                    class="emoji svg-inline--fa fa-fish fa-w-18"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="fish"
                    role="img"
                    viewBox="0 0 576 512">
                    <path
                      fill="currentColor"
                      d="M327.1 96c-89.97 0-168.54 54.77-212.27 101.63L27.5 131.58c-12.13-9.18-30.24.6-27.14 14.66L24.54 256 .35 365.77c-3.1 14.06 15.01 23.83 27.14 14.66l87.33-66.05C158.55 361.23 237.13 416 327.1 416 464.56 416 576 288 576 256S464.56 96 327.1 96zm87.43 184c-13.25 0-24-10.75-24-24 0-13.26 10.75-24 24-24 13.26 0 24 10.74 24 24 0 13.25-10.75 24-24 24z"
                    ></path>
                  </svg>
                </button>
              </a>
              <div class="text-shell ">
                Vendégeinknek csónakot is tudunk biztosítani!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-between mt-4">
        <div class="col-12 col-sm-6">
          <div class="row h-100 pr-0 pr-sm-3">
            <!-- Card shema -->
            <div class="border-card col px-4 py-2 py-sm-3 text-center">
              <div class="row h-100">
                <div class="text-shell-title col-12 text-center">
                  <b>Szent Korona Cukrászda</b>
                </div>
                <div class="col-12 align-self-end mt-3 mb-2">
                  <!-- Information button -->
                  <a href="http://www.szentkoronacukraszda.hu/" target="_blank">
                    <app-further-information-button></app-further-information-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 mt-4 mt-sm-0">
          <div class="row h-100 pl-0 pl-sm-3">
            <!-- Card shema -->
            <div class="border-card col px-4 py-2 py-sm-3 text-center">
              <div class="row h-100">
                <div class="text-shell-title col-12 text-center">
                  <b>Motormúzeum</b>
                </div>
                <div class="col-12 align-self-end mt-3 mb-2">
                  <!-- Information button -->
                  <a href="https://www.facebook.com/motormuzeum/" target="_blank">
                    <app-further-information-button></app-further-information-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card shema -->
      <div class="border-card row mt-4 px-4 py-2 py-sm-3">
        <div class="col p-0">
          <div class="text-shell-title"><b>Méhészet</b></div>
          <div class="text-shell">
            Méhészet megtekintése, méhek csodálatos világának megismerése, akár
            a velük való munka gyakorlása.
          </div>
        </div>
      </div>
      <!-- Card shema -->
      <div class="border-card row mt-4 px-4 py-2 py-sm-3">
        <div class="col p-0">
          <div class="text-shell-title">
            <b>Állatsimogató</b>
          </div>
          <div class="text-shell">
            kecskék, birkák, szárnyasok (óriás Bráma), törpemalacok.
          </div>
        </div>
      </div>
            <!-- Card shema -->
            <div class="border-card row mt-4 px-4 py-2 py-sm-3">
              <div class="col p-0">
                <div class="text-shell">
                  Ha kedved van beszálhattok a fejésbe, sajtkészítésbe, etetésbe, itatásba, és ha nem vagytok fáradtak akár a trágyavilla szakszerű használatát is betanítjuk
                  <!-- Smile icon -->
                  <svg
                  class="emoji svg-inline--fa fa-smile fa-w-16"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="smile"
                  role="img"
                  viewBox="0 0 496 512">
                  <path
                    fill="currentColor"
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"
                  ></path>
                </svg> !
                </div>
              </div>
            </div>
      <div class="text-title text-center mt-5">Kiskőrös</div>
      <div class="row mt-4">
        <!-- Card shema -->
        <div class="border-card col-12 col-sm mr-0 mr-sm-4 mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100">
            <div class="text-shell-title col-12 text-center">
              <b>Petőfi szülőháza</b>
            </div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://www.petofimuzeum.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col-12 col-sm mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100">
            <div class="text-shell-title col-12 text-center">
              <b>Úttörténeti múzeum</b>
            </div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://muzeum.kozut.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col-12 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100">
            <div class="text-shell-title col-12 text-center">
              <b>Termálfürdő</b>
            </div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="https://www.termalfurdo.hu/furdo/kiskoros-termalfurdo-es-tanuszoda-kemping-141" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="text-title text-center mt-5">
        Fél órás autóúttal elérhető további látnivalók
      </div>
      <div class="row mt-4">
        <!-- Card shema -->
        <div class="border-card col-12 col-sm mr-4 mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Szélmalom</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12 text-center align-self-start">
              Kiskunhalas
            </div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://www.museum.hu/muzeum/1583/Safrik-szelmalom" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Termálfürdő</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12 text-center">Kiskunhalas</div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="https://halasthermal.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col-12 mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Halasi csipkemúzeum</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12 text-center align-self-start">
              Kiskunhalas
            </div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://halasicsipke.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col-12 mr-0 mr-sm-4 col-sm mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Termálfürdő</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12 text-center">Kiskunmajsa</div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://www.jonathermal.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Hajósi pincefalu</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12">Hajós</div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://www.hajosi-pincefalu.hu/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
        <!-- Card shema -->
        <div class="border-card col-12 mb-4 px-3 py-2 py-sm-3 text-center">
          <div class="row h-100 px-3 px-sm-1 px-md-3">
            <div class="text-shell-title col-12 text-center">
              <b>Hadtörténeti múzeum</b>
            </div>
            <!-- Own style hr tag -->
            <div class="hr-border col-12 my-2"></div>
            <div class="text-shell col-12 text-center">Kecel</div>
            <div class="col-12 align-self-end mt-3 mb-2">
              <!-- Information button -->
              <a href="http://pintermuvek.hu/hadtechnikai-park/" target="_blank">
                <app-further-information-button></app-further-information-button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
