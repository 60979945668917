import { Component, OnDestroy, OnInit } from '@angular/core';

import { SeoService } from '../shared/seo.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {

  constructor(private seo: SeoService) { }

  ngOnInit(): void {
    this.seo.generateTags({
      title: "Boróka Ház - Kapcsolat"
    })
  }

  date() {
    let date = new Date()
    return date;
  }

  ngOnDestroy(): void {
    this.seo.generateTags({
      slug: ""
    })
  }
}
