import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private title: Title, @Inject(DOCUMENT) private document: Document) { }

  // Az Angular SSR módja miatt kell így lekérni a location-t, ami tartalmazza a teljes location-t.
  // Hivatalos probléma miatt van így: https://github.com/angular/universal/blob/master/docs/gotchas.md
  // Ha nem így csináljuk meg, akkor SSR módban "document is not defined" problémát fog dobni.
  getLocation(): Location {
    return this.location = this.document.location;
  }

  location!: Location;

  generateTags(config:any) {

    this.getLocation()

    // default values
    config = {
      title: 'Boróka Ház',
      description: 'Olcsó szállás, kedves vendéglátással, kiváló program lehetőségek.',
      image: this.location ? `${this.location.origin}assets/favicon-thumbnail/boroka-haz-thumbnail.png` : '',
      slug: '',
      author: 'Papp Viktor Tibor',
      url: this.location ? this.location.href : '',
      type: 'website',
      ...config
    }

    this.meta.updateTag({ name: 'twitter:site', content: 'Boróka Ház' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: config.type });
    this.meta.updateTag({ property: 'og:site_name', content: 'Boróka Ház' });
    this.meta.updateTag({ property: 'og:title', content: config.title});
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: config.url });

    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'author', content: config.author })
    this.title.setTitle( config.title )
  }

}
