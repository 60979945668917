<div class="container-fluid">
  <div id="sky" class="row pt-5 pb-3 pb-sm-5 justify-content-center align-items-center">
    <div class="col-auto">
      <div class="text-title mt-4 mt-sm-0 text-center">Boróka Ház</div>
      <div class="text-title text-center">Soltvadkert</div>
    </div>
  </div>
  <div id="apartment-main-page-container" class="row py-3 py-sm-5 justify-content-center">
    <!-- Picture of the house  -->
    <div class="col-11 col-xl-8">
      <img id="apartment-main-page" class="img-fluid" src="assets/boroka-haz.jpg" />
    </div>
  </div>
</div>
<div class="bg-color-ground text-color-ground container-fluid pb-3 pb-sm-5">
  <div class="row justify-content-center">
    <div class="col-11 col-xs-10 col-md-9 col-xl-6">
      <div class="text-title text-center">Szállásunk</div>
      <div class="text-shell my-4">Üdvözöllek!</div>
      <div class="text-shell ">Hadd mutassam be a mi kis apartman házunkat, a Boróka házat.</div>
      <div class="text-shell">Soltvadkerten, a Vadkerti-tótól 800 méterre található házunk, mely egy teljesen elszeparált, hatalmas kerttel rendelkező kétszintes apartmant rejt.</div>
      <div class="text-shell my-4 ">Igény szerint csak az alsó szint, vagy az egész ház bérelhető.</div>
      <div class="text-shell ">Az alsó szinten két hálószoba franciaággyal, mindkét szobában egy plusz ágy.</div>
      <div class="text-shell my-4 ">A felső szinten még két franciaágyas szoba van, az egyik szép nagy, a másik kuckós.
        <!-- Smile icon -->
        <svg
          class="emoji svg-inline--fa fa-smile fa-w-16"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="smile"
          role="img"
          viewBox="0 0 496 512">
          <path
            fill="currentColor"
            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z">
          </path>
        </svg>
      </div>
      <div class="text-shell ">Jól felszerelt óriási konyha, fedett terasz, dézsa, grill és bográcsozó hely, ping-pong asztal, homokozó, hinta.</div>
      <div class="text-shell my-4 ">A szomszédos telken megismerkedhetsz kecskéinkkel, belekóstolhatsz a vidéki élet szépségeibe, például sajtkészítés, kecsketartás.</div>
      <div class="text-shell ">A háztól pár percre megnézheted méhészetünket, megkóstolhatod mézeinket!</div>
    </div>
  </div>
</div>
<app-footer></app-footer>
